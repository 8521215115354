<template>
  <div>
    <van-empty description="暂无数据" v-if="grid.ls.length==0"/>
    <div v-else>
      <van-notice-bar left-icon="volume-o" text="直接点击记录，可以看详情和审批处理。"/>
      <van-list :finished="grid.finish" @load="loadPage" v-model="grid.loading" :immediate-check="false"  style="padding: 10px 0px;">
        <div class="wcard" v-for="item in grid.ls" :key="item.ID" @click="edit(item.ID)">
          <div class="bd">
            <van-row :gutter="8">
              <van-col :span="24">
                <p>标题：{{ item.TITLE }}</p>
                <p style="font-size: 14px; color: #999">单号：{{item.SN}}</p>
                <p style="font-size: 14px; color: #999">步骤：{{item.STEP_DESC}}</p>
              </van-col>
            </van-row>
          </div>
          <div class="ft">
            <van-row>
              <van-col :span="8" :class="item.STA==10?'':'ov'">
                状态：{{item.STA_TXT}}
              </van-col>
              <van-col :span="8">提交人：{{ item.SUB_NAME }}</van-col>
              <van-col :span="8">日期：{{item.CREATED_DT|datetime('YYYY-MM-DD')}}</van-col>
            </van-row>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  name: "lst",
  data(){
    return{
      grid:{
        sea:{
          PAGE_SIZE: 10,
          PAGE_INDEX: -1
        },
        ls:[],
        total:0,
        loading:false,
        finish:false
      }
    }
  },
  created() {

    this.grid.sea.FOR=this.$route.query.for;
    this.grid.sea.STA=this.$route.query.STA;
    if(this.$route.query.STA==15){
      document.title='归还'
    }
    this.filter();
  },
  methods:{
    filter(){
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.grid.finish=false;
      this.getList();
    },
    loadPage(){
      this.grid.sea.PAGE_INDEX+=1;
      this.getList();
    },
    getList(){
      let self = this;
      this.grid.loading=true;
      this.whale.remote.getCollection({
        url: "/api/Mobile/RENT/RDatApi/GetList",
        data: this.grid.sea,
        finally(){self.grid.loading=false;},
        completed: function (its, n) {
          self.grid.ls.push(...its.ITEMS);
          if (self.grid.ls.length>=n) self.grid.finish=true;
        }
      })
    },
    edit(id){
      this.$router.push({path:"/rent/edit",query:{ID:id}});
    }
  }
}
</script>
<style scoped>
.wcard {margin: 0 12px 12px; overflow: hidden; background-color: #fff; border-radius: 8px; box-shadow: 0 0 4px rgba(0,0,0,.1)}
.wcard .ft {border-top: 1px dashed #ebedf0; padding: 8px 16px; font-size: 12px; min-height: 20px}
.wcard .ft .ov {color: #ee0a24; font-weight: bold}
.wcard .bd {padding: 0px 15px}
.wcard .bd .lf {text-align: center; color:#ee0a24; padding-top: 10px}
.wcard .bd .lf h2 {font-size: 30px; font-weight: 500; display:inline;}
.wcard .bd .lf span {font-size: 40%; font-weight: normal; margin-left: 2px}
.wcard .bd .lf p {font-size: 14px; line-height: 16px}
</style>
